import { useTranslation } from 'react-i18next';

import { CowsCopyReasonEnum, DefaultEventEnum } from '@graphql-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';
import { match, P } from 'ts-pattern';

import { EnumStrings } from '~/~legacy/strings/enumStrings';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { TextLink } from '~/shared/components/TextLink';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { INTERPUNCT, SPACED_INTERPUNCT } from '~/shared/constants';
import { formatDate } from '~/shared/helpers/date';
import { formatFullNameWithInitials } from '~/shared/helpers/nameFormat';
import { joinJsxArray } from '~/shared/helpers/render';

import { formatCow, isBullCow } from '~/entities/cows';
import { getInseminationInfo } from '~/entities/inseminations';

import { CowDefaultEventFragment } from '../gql/fragments/cowDefaultEvent.graphql';
import { CowEventFragment } from '../gql/fragments/cowEvent.graphql';
import {
  isCowDefaultEvent,
  isCowDisease,
  isCowInjection,
  isCowProtocol,
} from '../helpers';

interface CowEventAdditionalInfo {
  happenedAt?: string;
  isProtocolStop?: boolean;
  groupedEvents?: CowEventFragment[];
}

/**
 * Hook for reusing cow event additional info that depends on the type of the event.
 * Also, we have special display variant for the cow card, if we pass additional event info.
 */
export const useGetCowEventAdditionalInfo = () => {
  const { t } = useTranslation(MaslovNamespaces.enums);

  return (
    cowEvent: CowEventFragment,
    additionalInfo?: CowEventAdditionalInfo
  ) =>
    match(cowEvent)
      .with(P.when(isCowDisease), matchedEvent => {
        if (!additionalInfo) return '';

        return (
          <>
            <div>{formatDate(matchedEvent.happenedAt)}</div>
            <Typography
              variant={TypographyVariants.descriptionLarge}
              className="text-muted"
            >
              Начало болезни
            </Typography>
          </>
        );
      })
      .with(P.when(isCowProtocol), matchedEvent => {
        if (!additionalInfo) return '';

        const diseaseNameSuffix = matchedEvent.diseaseName
          ? ` для болезни ${matchedEvent.diseaseName}`
          : '';

        return (
          <>
            <div>{formatDate(additionalInfo.happenedAt)}</div>
            <Typography
              variant={TypographyVariants.descriptionLarge}
              className="text-muted"
            >
              {additionalInfo.isProtocolStop
                ? `Конец протокола${diseaseNameSuffix}`
                : `Начало протокола${diseaseNameSuffix}`}
            </Typography>
          </>
        );
      })
      .with(P.when(isCowInjection), matchedEvent => {
        if (!matchedEvent.cowProtocol) return '';

        const injectionDayNumber =
          dayjs(matchedEvent.happenedAt).diff(
            dayjs(matchedEvent.cowProtocol.happenedAt),
            'days'
          ) + 1;

        return (
          <>
            {additionalInfo?.groupedEvents && (
              <div>
                {additionalInfo.groupedEvents
                  ?.filter(isCowInjection)
                  .map(e => e.injection.name)
                  .join(', ')}
              </div>
            )}
            <Typography
              variant={
                additionalInfo
                  ? TypographyVariants.descriptionLarge
                  : TypographyVariants.bodySmall
              }
              className={clsx(additionalInfo && 'text-muted')}
            >
              {injectionDayNumber}
              &nbsp;день&nbsp;из&nbsp;
              {matchedEvent.cowProtocol.protocol.expectedDaysOnProtocol}
            </Typography>
          </>
        );
      })
      .with(P.when(isCowDefaultEvent), matchedEvent => {
        const getCalvingInfo = (
          calving: CowDefaultEventFragment['calving']
        ) => {
          const totalAnimalsCount = calving?.results.length ?? 0;

          return (
            <ol
              key={calving?.id}
              className={clsx(
                'list-inside',
                totalAnimalsCount > 1 && 'list-revert'
              )}
            >
              {calving?.results.map(calvingResult => (
                <li key={calvingResult.id}>
                  {joinJsxArray(
                    [
                      calvingResult.isBull || isBullCow(calvingResult.cow)
                        ? 'Бык'
                        : 'Тёлка',
                      calvingResult.cow && (
                        <TextLink
                          key="cowLink"
                          {...{
                            to: '/$companyId/user/cows/$cowId',
                            params: prev => ({
                              ...prev,
                              cowId: calvingResult.cow?.id,
                            }),
                            typographyVariant:
                              TypographyVariants.descriptionLarge,
                          }}
                        >
                          {formatCow(calvingResult.cow, { prefix: '' })}
                        </TextLink>
                      ),
                      calvingResult.isDead ? 'Мёртв' : 'Жив',
                    ],
                    SPACED_INTERPUNCT
                  )}
                </li>
              ))}
            </ol>
          );
        };

        const getCopyKeyInfo = (
          copyKey: typeof matchedEvent.copyKey,
          eventShortCode: DefaultEventEnum
        ) => {
          if (!copyKey) return false;

          const { reason } = copyKey;

          const isEnterShortCode = eventShortCode === DefaultEventEnum.Enter;
          const toCompanyName =
            copyKey.toCompany?.name ?? copyKey.toCompanyName;
          const companyName = copyKey.company.name;
          const fullNameWithInitials = formatFullNameWithInitials(
            copyKey.creator
          );

          return match(reason)
            .with(CowsCopyReasonEnum.Sell, () =>
              isEnterShortCode
                ? `Куплено в компании ${companyName} ${INTERPUNCT} Ключ активировал ${fullNameWithInitials}`
                : `В компанию ИНН ${copyKey.toCompanyInn} ${INTERPUNCT} Ключ сформир. ${fullNameWithInitials}`
            )
            .with(CowsCopyReasonEnum.Move, () =>
              isEnterShortCode
                ? `Перемещено с площадки ${companyName} ${INTERPUNCT} Ключ активировал ${fullNameWithInitials}`
                : `На площадку ${toCompanyName} ${INTERPUNCT} Ключ сформир. ${fullNameWithInitials}`
            )
            .exhaustive();
        };

        const infoList = [
          !!matchedEvent.daysInPregnancy &&
            `Дней стельности ${matchedEvent.daysInPregnancy}`,
          !!matchedEvent.retirementReason &&
            `Причина выбытия: ${t(
              `${EnumStrings.cowRetirementReason}${matchedEvent.retirementReason}`
            )}`,
          getCopyKeyInfo(matchedEvent.copyKey, matchedEvent.event.shortcode),
          !!matchedEvent.penGroupFrom &&
            `Из группы ${matchedEvent.penGroupFrom.name}`,
          !!matchedEvent.penGroupTo &&
            `В группу ${matchedEvent.penGroupTo.name}`,
          !!matchedEvent.optionalProtocol &&
            `Протокол ${matchedEvent.optionalProtocol.name}`,
          !!matchedEvent.bodyConditionScore &&
            `BCS ${matchedEvent.bodyConditionScore.bodyConditionScore}`,
          !!matchedEvent.heightMeasurement?.heightCentimeters &&
            `Рост ${matchedEvent.heightMeasurement.heightCentimeters} см`,
          !!matchedEvent.weighing?.weightKilograms &&
            `Вес ${matchedEvent.weighing.weightKilograms} кг`,
          !!matchedEvent.insemination &&
            getInseminationInfo(matchedEvent.insemination),
          matchedEvent.calving && getCalvingInfo(matchedEvent.calving),
        ];

        return joinJsxArray(infoList, <br />);
      })
      .otherwise(R.always(''));
};
