import React, { useMemo } from 'react';

import { ReproductionYAxisMode } from '@graphql-types';
import { ChartOptions } from 'chart.js';
import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';

import { DATE_RANGE_REGEXP, formatDateRange } from '~/shared/helpers/date';

import {
  BarChart,
  CHART_COLOR_PROPS_ITERATOR,
  CHART_X_SCALE_MIN_RANGE,
  CHART_Y_SCALE_MIN_RANGE,
  DataPointsTooltip,
  DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
  DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
} from '~/features/charts';

import panelStyles from '~/styles/modules/panel.module.scss';

import { REPRODUCTION_CALCULATION_PERIOD_DAYS } from '../../constants';
import { ReproductionHdrAndPrRowsByDateRowFragment } from '../../gql/fragments/reproductionHdrAndPrRowsByDateRow.graphql';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Periods to display on the chart
   */
  reportRows: ReproductionHdrAndPrRowsByDateRowFragment[];
  /**
   * Mode for y axis
   */
  yAxisMode?: ReproductionYAxisMode;
}

const CHART_HEIGHT_PX = 412;

export const ReproductionHdrAndPrChart: React.FC<Props> = ({
  className,
  reportRows,
  yAxisMode = ReproductionYAxisMode.Percent_100,
}) => {
  const xAxisName = 'Дата';
  const xAxisLabels = useMemo(
    () =>
      reportRows.map(row => {
        return formatDateRange(
          row.date,
          dayjs(row.date).add(REPRODUCTION_CALCULATION_PERIOD_DAYS, 'days')
        ).split(DATE_RANGE_REGEXP);
      }),
    [reportRows]
  );

  const { datasets, chartOptions } = useMemo(() => {
    const hdrDataset = {
      key: 'hdr',
      label: 'Выявление (HDR)',
      ...CHART_COLOR_PROPS_ITERATOR.next(true).value,
      isTogglable: true,
      order: 1,
      data: reportRows.map(row => row.hdr ?? NaN),
    };

    const prDataset = {
      key: 'pr',
      label: 'Стельность (PR)',
      ...CHART_COLOR_PROPS_ITERATOR.next().value,
      isTogglable: true,
      order: 2,
      data: reportRows.map(row => row.pr ?? NaN),
    };

    const crDataset = {
      key: 'cr',
      type: 'line',
      label: 'Плодотворные осеменения (CR)',
      ...CHART_COLOR_PROPS_ITERATOR.next().value,
      isTogglable: true,
      order: 0,
      data: reportRows.map(row => row.cr ?? NaN),
    };

    const datasetsInternal = [hdrDataset, prDataset, crDataset];

    const chartOptionsInternal = {
      interaction: {
        mode: 'index',
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: true,
            text: xAxisName,
          },
          grid: {
            display: true,
            offset: false,
          },
        },
        y: {
          max:
            yAxisMode === ReproductionYAxisMode.Percent_100
              ? 100
              : Math.max(
                  ...datasetsInternal
                    .flatMap(dataset => dataset.data)
                    .filter(R.complement(Number.isNaN))
                ),
        },
      },
      plugins: {
        zoom: {
          limits: {
            x: {
              min: 'original',
              max: 'original',
              minRange: CHART_X_SCALE_MIN_RANGE,
            },
            y: {
              min: 'original',
              max: 'original',
              minRange: CHART_Y_SCALE_MIN_RANGE,
            },
          },
          zoom: DEFAULT_ZOOM_PLUGIN_ZOOM_OPTIONS,
          pan: DEFAULT_ZOOM_PLUGIN_PAN_OPTIONS,
        },
      },
    } satisfies ChartOptions;

    return {
      datasets: datasetsInternal,
      chartOptions: chartOptionsInternal,
    };
  }, [reportRows, yAxisMode]);

  return (
    <div className={clsx(panelStyles.borderedPanel, 'p-16', className)}>
      <BarChart
        {...{
          datasets,
          labels: xAxisLabels,
          legendClassName: 'mb-32',
          height: CHART_HEIGHT_PX,
          renderTooltip: dataPoints => {
            const sortedPoints = R.sortBy(R.prop('datasetIndex'), dataPoints);
            const dateIndex = sortedPoints.at(0)?.parsed.x;
            if (R.isNil(dateIndex)) {
              return null;
            }
            const xAxisLabel = xAxisLabels.at(dateIndex)?.join(' ');
            if (!xAxisLabel) {
              return null;
            }
            return (
              <DataPointsTooltip
                {...{
                  title: `${xAxisName} ${xAxisLabel}`,
                  dataPoints: sortedPoints,
                  datasets,
                  mainValueMeasurementUnit: '%',
                }}
              />
            );
          },
          chartOptions,
          isPercents: true,
        }}
      />
    </div>
  );
};
