import React, { useMemo } from 'react';

import { useApolloClient } from '@apollo/client';

import { CowRetirementReason, CowState } from '@graphql-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import R from 'ramda';
import * as yup from 'yup';

import { DateInput } from '~/shared/components/DateInput';
import {
  ExpandableBlock,
  ExpandableBlockVariants,
} from '~/shared/components/ExpandableBlock';
import { Input, InputVariants } from '~/shared/components/Input';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';
import { oneOfEnum } from '~/shared/helpers/yup';

import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { makeDeleteQuery } from '~/services/gql';
import { InjectedModalProps, Modal } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import { readFarmFragment, useFarmSelect } from '~/entities/farms';
import {
  PenGroupAsyncSelect,
  readPenGroupFragment,
} from '~/entities/penGroups';

import formStyles from '~/styles/modules/form.module.scss';

import {
  HERRIOT_CODE_ERROR_MESSAGE,
  HERRIOT_CODE_REGEXP,
} from '../../constants';
import { CowDetailedFragment } from '../../gql/fragments/cowDetailed.graphql';
import { useCreateCowMutation } from '../../gql/mutations/createCow.graphql';
import { useUpdateCowMutation } from '../../gql/mutations/updateCow.graphql';
import { formatCow, updateCowDetailedFragment } from '../../helpers';
import { useCowRetirementReasonSelect, useCowStateSelect } from '../../hooks';
import styles from './index.module.scss';

export interface EditCowModalProps
  extends InjectedModalProps<EditCowModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing cow, if not passed, a new one is created
   */
  cow?: CowDetailedFragment;
}

const DEFAULT_NIPPLES_COUNT = 4;

const FORM_ID = 'EditCowForm';

const BLOCK_TITLE_TYPOGRAPHY_PROPS = {
  className: styles.blockTitle,
  tag: 'h4',
  variant: TypographyVariants.bodyMediumStrong,
} as const;

export const EditCowModal: React.FC<EditCowModalProps> = ({
  className,
  cow,
  close,
}) => {
  const { sendSuccessToast } = useNotifications();

  const isEditing = !!cow;

  const client = useApolloClient();

  const schema = useMemo(() => {
    return yup.object({
      // Required
      identifier: yup.number().required().default(null),
      farmID: yup.string().required(), // ID!
      penGroupID: yup.string().required(), // ID!
      state: oneOfEnum(CowState).default(CowState.Bred),
      dateOfBirth: yup.string().default('').required(), // DateTime!
      breed: yup.string().default('').required(),
      ...wrapConditionalObjectElement(
        !isEditing && {
          lactationNumber: yup.number().required().default(0),
          currentLactationHappenedAt: yup.string().required().default(''), // DateTime!
        }
      ),

      // Status
      comment: yup.string().default(''),

      // Identification
      name: yup.string().default(''),
      usdaNumber: yup.string().default(''),
      registrationNumber: yup.string().default(''),
      electronicTagNumber: yup.string().default(''),
      ...wrapConditionalObjectElement(
        isEditing && {
          earTagNumber: yup.string().default(''),
          collarNumber: yup.string().default(''),
        }
      ),
      herriotCode: yup
        .string()
        .default('')
        .matches(HERRIOT_CODE_REGEXP, HERRIOT_CODE_ERROR_MESSAGE),
      herriotCodeSetAt: yup.string().nullable().default(null), // DateTime

      // Identification history
      numberOnPreviousFarm: yup.string().default(''),
      ...wrapConditionalObjectElement(
        isEditing && {
          retiredAt: yup
            .string()
            .nullable()
            .default(null)
            .test(
              'checkDateRange',
              'Выберите дату не позднее текущего дня',
              dateTime =>
                !dateTime || dayjs().isSameOrAfter(dayjs(dateTime), 'day')
            )
            .transform(date => date || null),
        }
      ),
      retirementReason: oneOfEnum(CowRetirementReason).nullable().default(null),

      // Pedigree
      motherName: yup.string().default(''),
      motherUsdaNumber: yup.string().default(''),
      fatherName: yup.string().default(''),
      fatherUsdaNumber: yup.string().default(''),

      // Cow data
      birthWeightKilograms: yup.number().nullable().default(null),
      currentWeightKilograms: yup.number().nullable().default(null),
      currentHeightCentimeters: yup.number().nullable().default(null),

      // Milk production
      compNumber: yup.string().default(''),
      transponderNumber: yup.string().default(''),
      nipplesCount: yup.number().default(DEFAULT_NIPPLES_COUNT),

      // Health
      lamenessScore: yup.number().default(0),

      // Common
      ...wrapConditionalObjectElement(
        isEditing && {
          bloodProteinTestValue: yup.number().nullable().default(null),
          bloodProteinTestHappenedAt: yup.string().nullable().default(null), // DateTime
          selexBreedID: yup.number().nullable().default(null),
        }
      ),
    });
  }, [isEditing]);

  type EditCowFormType = InferSchemaWithDefaults<typeof schema>;
  type EditCowFormTransformedType = InferValidatedSchema<typeof schema>;

  const {
    itemsPromise: farmsItemsPromise,
    renderSelectElement: renderFarmsSelectElement,
  } = useFarmSelect({
    selectProps: {
      label: 'Ферма',
      name: 'farmID',
    },
  });

  const formContext = useForm<EditCowFormType, EditCowFormTransformedType>({
    schema,

    defaultValues: () =>
      farmsItemsPromise.then(items => ({
        ...schema.getDefault(),
        ...R.pick(
          [
            'identifier',
            'dateOfBirth',
            'state',
            'comment',
            'name',
            'usdaNumber',
            'registrationNumber',
            'earTagNumber',
            'collarNumber',
            'electronicTagNumber',
            'herriotCode',
            'herriotCodeSetAt',
            'numberOnPreviousFarm',
            'retiredAt',
            'retirementReason',
            'motherName',
            'motherUsdaNumber',
            'fatherName',
            'fatherUsdaNumber',
            'birthWeightKilograms',
            'breed',
            'currentWeightKilograms',
            'currentHeightCentimeters',
            'compNumber',
            'transponderNumber',
            'nipplesCount',
            'lamenessScore',
            'bloodProteinTestValue',
            'bloodProteinTestHappenedAt',
            'selexBreedID',
          ],
          cow ?? ({} as CowDetailedFragment)
        ),
        farmID: cow?.farm.id ?? items[0]?.id,
        penGroupID: cow?.penGroup.id,
      })),
  });

  const [createCow, { loading: isCreateCowLoading }] = useCreateCowMutation();

  const [updateCow, { loading: isUpdateCowLoading }] = useUpdateCowMutation();

  const handleSubmit = async (form: EditCowFormTransformedType) => {
    if (isEditing) {
      await updateCow({
        variables: {
          id: cow.id,
          input: form,
        },
        update: updateCowDetailedFragment(cow.id, draft => {
          draft.name = form.name;
          draft.identifier = form.identifier;
          draft.dateOfBirth = form.dateOfBirth;
          draft.state = form.state;
          draft.comment = form.comment;
          draft.usdaNumber = form.usdaNumber;
          draft.registrationNumber = form.registrationNumber;
          draft.earTagNumber = form.earTagNumber;
          draft.collarNumber = form.collarNumber;
          draft.electronicTagNumber = form.electronicTagNumber;
          draft.herriotCode = form.herriotCode;
          draft.herriotCodeSetAt = form.herriotCodeSetAt;
          draft.numberOnPreviousFarm = form.numberOnPreviousFarm;
          draft.retirementReason = form.retirementReason;
          draft.retiredAt = form.retiredAt;
          draft.motherName = form.motherName;
          draft.motherUsdaNumber = form.motherUsdaNumber;
          draft.fatherName = form.fatherName;
          draft.fatherUsdaNumber = form.fatherUsdaNumber;
          draft.birthWeightKilograms = form.birthWeightKilograms;
          draft.breed = form.breed;
          draft.currentWeightKilograms = form.currentWeightKilograms;
          draft.currentHeightCentimeters = form.currentHeightCentimeters;
          draft.compNumber = form.compNumber;
          draft.transponderNumber = form.transponderNumber;
          draft.nipplesCount = form.nipplesCount;
          draft.lamenessScore = form.lamenessScore;
          draft.bloodProteinTestValue = form.bloodProteinTestValue;
          draft.bloodProteinTestHappenedAt = form.bloodProteinTestHappenedAt;
          draft.selexBreedID = form.selexBreedID;

          const farmFragment = readFarmFragment(client, form.farmID);
          if (farmFragment) {
            draft.farm = farmFragment;
          }

          const penGroupFragment = readPenGroupFragment(
            client,
            form.penGroupID
          );
          if (penGroupFragment) {
            draft.penGroup = penGroupFragment;
          }
        }),
        refetchQueries: ['cowDetailed'],
      });
    } else {
      await createCow({
        variables: {
          input: {
            ...form,
            retirementReason: form.retirementReason ?? null,
          },
        },
        update: makeDeleteQuery('penGroups'),
      }).then(() => {
        sendSuccessToast('Животное добавлено');
      });
    }
    close();
  };

  const { renderSelectElement: renderCowStateSelectElement } =
    useCowStateSelect({
      name: 'state',
      placeholder: 'Выберите статус',
      label: 'Статус животного',
    });

  const { renderSelectElement: renderCowRetirementReasonSelectElement } =
    useCowRetirementReasonSelect({
      name: 'retirementReason',
      placeholder: 'Выберите причину',
      label: 'Причина выбытия',
      isClearable: true,
    });

  return (
    <Modal
      {...{
        className,
        title: isEditing
          ? formatCow(cow, { withName: false })
          : 'Новое животное',
        submitButtonProps: {
          form: FORM_ID,
          isLoading: isCreateCowLoading || isUpdateCowLoading,
          children: isEditing ? 'Сохранить' : 'Создать',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Typography tag="h3" variant={TypographyVariants.heading4}>
          Обязательная информация
        </Typography>
        <div className={clsx('mt-16', formStyles.twoColumnForm)}>
          <Input
            {...{
              name: 'identifier',
              label: 'Рабочий номер',
              variant: InputVariants.int,
              // We don't format cow number and now we don't have a way to distinct it from other int inputs
              withFormat: false,
            }}
          />
          {renderFarmsSelectElement()}
          <PenGroupAsyncSelect
            {...{ name: 'penGroupID', label: 'Номер группы' }}
          />
          <DateInput
            {...{
              name: 'dateOfBirth',
              label: 'Дата рождения',
            }}
          />
          {renderCowStateSelectElement()}
          <Input
            {...{
              name: 'breed',
              label: 'Порода',
            }}
          />
          {!isEditing && (
            <>
              <Input
                {...{
                  name: 'lactationNumber',
                  label: 'Текущая лактация',
                  variant: InputVariants.int,
                }}
              />
              <DateInput
                {...{
                  name: 'currentLactationHappenedAt',
                  label: 'Дата начала текущей лактации',
                }}
              />
            </>
          )}
        </div>

        <ExpandableBlock
          {...{
            className: 'mt-24',
            variant: ExpandableBlockVariants.header,
            openButtonText: 'Показать дополнительную информацию',
            closeButtonText: 'Скрыть дополнительную информацию',
            title: 'Дополнительная информация',
          }}
        >
          <Typography {...BLOCK_TITLE_TYPOGRAPHY_PROPS}>Статус</Typography>
          <Input
            {...{
              name: 'comment',
              label: 'Комментарий',
            }}
          />

          <Typography {...BLOCK_TITLE_TYPOGRAPHY_PROPS}>
            Идентификация
          </Typography>
          <div className={formStyles.twoColumnForm}>
            <Input
              {...{
                name: 'name',
                label: 'Кличка',
              }}
            />
            <Input
              {...{
                name: 'usdaNumber',
                label: 'Международный номер',
              }}
            />
            <Input
              {...{
                name: 'registrationNumber',
                label: 'Регистрационный номер РФ',
              }}
            />
            <Input
              {...{
                name: 'electronicTagNumber',
                label: 'Номер электронного чипа',
              }}
            />
            {isEditing && (
              <>
                <Input
                  {...{
                    name: 'earTagNumber',
                    label: 'Номер ушной бирки',
                  }}
                />
                <Input
                  {...{
                    name: 'collarNumber',
                    label: 'Номер ошейника',
                  }}
                />
              </>
            )}
            <Input
              {...{
                name: 'herriotCode',
                label: 'Хорриот код',
              }}
            />
            <DateInput
              {...{
                name: 'herriotCodeSetAt',
                label: 'Дата основной маркировки',
              }}
            />
          </div>

          <Typography {...BLOCK_TITLE_TYPOGRAPHY_PROPS}>
            История идентификации
          </Typography>
          <div className={formStyles.singleColumnForm}>
            <Input
              {...{
                name: 'numberOnPreviousFarm',
                label: 'Номер на предыдущей ферме',
              }}
            />
            <div className={formStyles.twoColumnForm}>
              {renderCowRetirementReasonSelectElement()}
              {isEditing && (
                <DateInput
                  {...{
                    name: 'retiredAt',
                    label: 'Дата выбытия',
                  }}
                />
              )}
            </div>
          </div>
          <Typography {...BLOCK_TITLE_TYPOGRAPHY_PROPS}>Родословная</Typography>
          <div className={formStyles.twoColumnForm}>
            <Input
              {...{
                name: 'motherName',
                label: 'Кличка матери',
              }}
            />
            <Input
              {...{
                name: 'motherUsdaNumber',
                label: 'Международный номер матери',
              }}
            />
            <Input
              {...{
                name: 'fatherName',
                label: 'Кличка отца',
              }}
            />
            <Input
              {...{
                name: 'fatherUsdaNumber',
                label: 'Международный номер отца',
              }}
            />
          </div>

          <Typography {...BLOCK_TITLE_TYPOGRAPHY_PROPS}>
            Данные животного
          </Typography>
          <div className={formStyles.threeColumnForm}>
            <Input
              {...{
                name: 'birthWeightKilograms',
                label: 'Вес при рождении',
                variant: InputVariants.float,
              }}
            />
            <Input
              {...{
                name: 'currentWeightKilograms',
                label: 'Текущий вес',
                variant: InputVariants.float,
              }}
            />
            <Input
              {...{
                name: 'currentHeightCentimeters',
                label: 'Текущий рост',
                variant: InputVariants.float,
              }}
            />
          </div>

          <Typography {...BLOCK_TITLE_TYPOGRAPHY_PROPS}>
            Производство молока
          </Typography>
          <div className={formStyles.twoColumnForm}>
            <Input
              {...{
                name: 'compNumber',
                label: 'Номер в доильном зале',
              }}
            />
            <Input
              {...{
                name: 'transponderNumber',
                label: 'Номер транспондера',
              }}
            />
            <Input
              {...{
                className: 'col-span-full',
                name: 'nipplesCount',
                label: 'Количество сосков',
                variant: InputVariants.int,
              }}
            />
          </div>

          <Typography {...BLOCK_TITLE_TYPOGRAPHY_PROPS}>Здоровье</Typography>
          <Input
            {...{
              name: 'lamenessScore',
              label: 'Степень хромоты',
              variant: InputVariants.int,
            }}
          />

          {isEditing && (
            <>
              <Typography {...BLOCK_TITLE_TYPOGRAPHY_PROPS}>Общая</Typography>
              <div className={formStyles.twoColumnForm}>
                <Input
                  {...{
                    name: 'bloodProteinTestValue',
                    label: 'Белок в крови',
                    variant: InputVariants.float,
                  }}
                />
                <DateInput
                  {...{
                    name: 'bloodProteinTestHappenedAt',
                    label: 'Дата взятия белка в крови',
                  }}
                />
                <Input
                  {...{
                    className: 'col-span-full',
                    name: 'selexBreedID',
                    label: 'Код породы в Селэкс',
                    variant: InputVariants.int,
                  }}
                />
              </div>
            </>
          )}
        </ExpandableBlock>
      </Form>
    </Modal>
  );
};
